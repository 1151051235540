import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { BusinessCustomerFragment } from './BusinessCustomerFragment'
import { ImageFragment } from './ImageFragment'

export const CustomerFragment = () =>
	Selector('Customer')({
		id: true,
		email: true,
		name: [{}, { nominative: true }],
		photo: [{}, ImageFragment()],
		business: [{}, { id: true }],
		user: [{}, { id: true }],
		anonymousSession: [{}, { id: true }],
		meta: [
			{},
			{
				remainingCreditsCents: true,
			},
		],
		memberOfBusinesses: [
			{},
			{
				id: true,
				category: [{}, { internalName: true }],
				customer: [{}, BusinessCustomerFragment()],
			},
		],
		ownerOfBusinesses: [
			{},
			{
				id: true,
				category: [{}, { internalName: true }],
				customer: [{}, BusinessCustomerFragment()],
			},
		],
	})

export type CustomerResult = InputType<
	GraphQLTypes['Customer'],
	ReturnType<typeof CustomerFragment>
>
