import { GoodlokAuthForm } from '@goodlok/react-sdk'
import { FunctionComponent, useEffect, useState } from 'react'

export const SignIn: FunctionComponent = () => {
	const [successUrl, setSuccessUrl] = useState('/')
	useEffect(() => {
		setSuccessUrl(window.location.href)
	}, [])
	return <GoodlokAuthForm successUrl={successUrl} />
}
