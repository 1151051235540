import { Modal } from '@goodlok/react-sdk'
import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { assertNever, Switch, useMirrorLoading } from '@goodlok/ui'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { customerZeus } from '../data/Zeus/client/customer'
import type { AddressFormInputData } from '../forms/AddressFormInput'
import { useBusinessCustomerId } from '../hooks/useCustomer'
import { useListAddresses } from '../hooks/useListAddresses'
import { orderBillingAddressState, orderDeliveryAddressState } from '../states/order'
import { AddressForm } from './AddressForm'
import { SectionHeader } from './SectionHeader'

export const AddressFields: FunctionComponent = () => {
	const businessCustomerId = useBusinessCustomerId()
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()

	const [isModalOpen, setIsModalOpen] = useState(false)

	const addresses = useListAddresses()
	const [billingAddress, setBillingAddress] = useRecoilState<null | string>(
		orderBillingAddressState
	)
	const [deliveryAddress, setDeliveryAddress] = useRecoilState<null | string>(
		orderDeliveryAddressState
	)
	useEffect(() => {
		if (!deliveryAddress) {
			setDeliveryAddress(
				addresses.data?.addresses.find(
					(address) => address.defaultDeliveryAddressOfCustomer !== null
				)?.id || null
			)
		}
		// if (!billingAddress) {
		// 	setBillingAddress(addresses.data?.defaultBillingAddress?.id || null)
		// }
	}, [addresses.data, billingAddress, deliveryAddress, setBillingAddress, setDeliveryAddress])

	const useCustomMutation = (onChange: (id: string | null) => void) =>
		useMutation(
			async (validatedData: AddressFormInputData) => {
				return customerZeus('mutation')({
					createAddress: [
						{
							data: {
								name: validatedData.name,
								fullName: validatedData.fullName,
								email: validatedData.email,
								phone: validatedData.phone,
								raw: validatedData.raw,
								customer: { connect: { id: businessCustomerId } },
								country: { connect: { code: 'CZ' } }, // @TODO
							},
						},
						{
							ok: true,
							errorMessage: true,
							node: {
								id: true,
							},
						},
					],
				}).then((data) => data)
			},
			{
				onSuccess: (data) => {
					if (data.createAddress.ok) {
						onChange(data.createAddress.node?.id || null)
					} else {
						alert(data.createAddress.errorMessage)
					}
					addresses.refetch()
					setIsModalOpen(false)
				},
			}
		)
	const createBillingAddressMutation = useCustomMutation(setBillingAddress)
	const createDeliveryAddressMutation = useCustomMutation(setDeliveryAddress)

	const [type, setType] = useState<'billing' | 'delivery'>('billing')

	useMirrorLoading(
		createBillingAddressMutation.isLoading || createDeliveryAddressMutation.isLoading
	)

	useEffect(() => {
		if (createBillingAddressMutation.isError || createDeliveryAddressMutation.isError) {
			alert('mutation failed')
		}
	}, [createBillingAddressMutation.isError, createDeliveryAddressMutation.isError])

	const options = useMemo(
		() =>
			addresses.data?.addresses.map((address) => ({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				value: address.id!, // @TODO schema generates ids as possibly undefined, probably acl bug
				label: (
					<>
						{address.name}
						<div>
							<small>
								{address.addressLine1}
								<br />
								{address.postalCode} {address.city}
							</small>
						</div>
					</>
				),
			})),
		[addresses]
	)

	return options ? (
		<>
			<SectionHeader
				title={translate('order.deliveryAddress.title')}
				description={translateWithoutFallback('order.deliveryAddress.description')}
			/>
			<Switch
				name="deliveryAddress"
				selectedValue={deliveryAddress}
				onChange={setDeliveryAddress}
				options={options}
				onRequestToAddNew={() => {
					setType('delivery')
					setIsModalOpen(true)
				}}
			/>

			<SectionHeader
				title={translate('order.billingAddress.title')}
				description={translateWithoutFallback('order.billingAddress.description')}
			/>
			<Switch
				name="billingAddress"
				selectedValue={billingAddress}
				onChange={setBillingAddress}
				options={options}
				onRequestToAddNew={() => {
					setType('billing')
					setIsModalOpen(true)
				}}
			/>

			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => {
					setIsModalOpen(false)
				}}>
				<AddressForm
					action="create"
					title={type === 'billing' ? 'Fakturační adresa' : 'Doručovací adresa'}
					onSubmit={(validatedData) => {
						if (type === 'billing') {
							createBillingAddressMutation.mutate(validatedData)
						} else if (type === 'delivery') {
							createDeliveryAddressMutation.mutate(validatedData)
						} else {
							assertNever(type)
						}
					}}
				/>
			</Modal>
		</>
	) : null
}
