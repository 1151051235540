import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { Switch } from '@goodlok/ui'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useChannelPaymentMethods } from '../hooks/useChannelPaymentMethods'
import { SectionHeader } from './SectionHeader'

export const PaymentMethods: FunctionComponent = () => {
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()

	const paymentMethods = useChannelPaymentMethods()
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null)

	useEffect(() => {
		if (!selectedPaymentMethod && paymentMethods.data && (paymentMethods.data?.length || 0) > 0) {
			setSelectedPaymentMethod(paymentMethods.data[0].method?.id || null)
		}
	}, [paymentMethods.data, selectedPaymentMethod])

	const options = useMemo(() => {
		if (!paymentMethods.data) {
			return []
		}
		return paymentMethods.data.map((paymentMethod) => {
			return {
				value: paymentMethod.method?.id || '',
				label: paymentMethod.method?.name || '',
			}
		})
	}, [paymentMethods.data])

	return paymentMethods.data ? (
		<>
			<SectionHeader
				title={translate('order.paymentMethods.title')}
				description={translateWithoutFallback('order.paymentMethods.description')}
			/>
			<Switch
				name="paymentMethod"
				selectedValue={selectedPaymentMethod}
				onChange={(value) => {
					setSelectedPaymentMethod(value)
				}}
				options={options}
			/>
		</>
	) : (
		<></>
	)
}
