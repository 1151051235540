import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { Switch } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import { SectionHeader } from './SectionHeader'

export const SeasonalProductsQuantity: FunctionComponent = () => {
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()

	const seasonalProductQuantities = [
		{ value: '10', label: '10 l' },
		{ value: '20', label: '20 l' },
		{ value: '30', label: '30 l' },
		{ value: '50', label: '50 l' },
	]
	return (
		<>
			<SectionHeader
				title={translate('order.seasonalProductsQuantity.title')}
				description={translateWithoutFallback('order.seasonalProductsQuantity.description')}
			/>
			<Switch
				name="seasonalProductsQuantity"
				options={seasonalProductQuantities}
				normalCasing
				customValueInput={{
					labelBefore: translate('order.seasonalProductsQuantity.custom'),
					labelAfter: 'l',
					type: 'number',
				}}
			/>
		</>
	)
}
