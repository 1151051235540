import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { Switch } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import { SectionHeader } from './SectionHeader'

export type FrequencySelectProps = {
	recurrences?: {
		id: string
		name: string
	}[]
	selectedFrequencyId: string | null
	onChange: (value: string | null) => void
}

export const FrequencySelect: FunctionComponent<FrequencySelectProps> = ({
	recurrences,
	selectedFrequencyId,
	onChange,
}) => {
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()

	return (
		<>
			<SectionHeader
				title={translate('order.frequency.title')}
				description={translateWithoutFallback('order.frequency.description')}
			/>
			<Switch
				name="frequency"
				selectedValue={selectedFrequencyId}
				onChange={onChange}
				options={
					recurrences?.map((recurrence) => ({
						label: recurrence.name,
						value: recurrence.id,
					})) ?? []
				}
			/>
		</>
	)
}
