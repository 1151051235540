import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { AddressFragment } from './AddressFragment'

export const BusinessCustomerFragment = () =>
	Selector('Customer')({
		id: true,
		addresses: [{}, AddressFragment()],
		meta: [
			{},
			{
				remainingCreditsCents: true,
			},
		],
		business: [
			{},
			{
				name: true,
			},
		],
		defaultDeliveryAddress: [
			{},
			{
				id: true,
			},
		],
		defaultBillingAddress: [
			{},
			{
				id: true,
			},
		],
	})

export type BusinessCustomerResult = InputType<
	GraphQLTypes['Customer'],
	ReturnType<typeof BusinessCustomerFragment>
>
