import { Button } from '@goodlok/react-sdk'
import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import type { FunctionComponent } from 'react'

export const OrderButton: FunctionComponent<{
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}> = (props) => {
	const translate = useTranslate()

	return (
		<>
			<Button onClick={props.onClick} type="button" size="large" variant="primary">
				{translate('button.proceedToPayment')}
			</Button>
		</>
	)
}
