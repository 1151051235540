import type { CSSProperties, FunctionComponent } from 'react'
import style from './Spacer.module.sass'

export interface SpacerProps {
	initial?: number
	// Keep in sync with Spacer.module.sass
	break800?: number
	break1200?: number
	break1500?: number
	break1900?: number
}

const getBreakCustomProperties = (props: SpacerProps): CSSProperties => {
	const breaks = [
		{ name: 'initial', value: props.initial },
		{ name: '800', value: props.break800 },
		{ name: '1200', value: props.break1200 },
		{ name: '1500', value: props.break1500 },
		{ name: '1900', value: props.break1900 },
	]

	for (let i = 1; i < breaks.length; i++) {
		if (typeof breaks[i].value === 'undefined' && typeof breaks[i - 1].value !== 'undefined') {
			breaks[i].value = breaks[i - 1].value
		}
	}

	const styles: CSSProperties = Object.fromEntries(
		breaks.map((item) => [`--spacer-break-${item.name}`, `${item.value}`] as const)
	)

	return styles
}

export const Spacer: FunctionComponent<SpacerProps> = (props) => {
	return <div className={style.Wrapper} style={getBreakCustomProperties(props)}></div>
}
