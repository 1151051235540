import type { FunctionComponent, MouseEventHandler } from 'react'
import style from './HamburgerMenuOpener.module.sass'

export type HamburgerMenuOpenerProps = {
	onClick: MouseEventHandler<HTMLButtonElement>
}

export const HamburgerMenuOpener: FunctionComponent<HamburgerMenuOpenerProps> = ({ onClick }) => {
	return (
		<button className={style.wrapper} type="button" aria-label="otevřít menu" onClick={onClick}>
			<span className={style.line} />
			<span className={style.line} />
			<span className={style.line} />
		</button>
	)
}
