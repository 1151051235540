import { Button } from '@goodlok/react-sdk'
import { Input } from '@goodlok/ui'
import clsx from 'clsx'
import { FunctionComponent, ReactNode, useState } from 'react'
import { AddressFormInput, AddressFormInputData } from '../forms/AddressFormInput'
import { validateZod } from '../validators/utils/validateZod'
import style from './AddressForm.module.sass'
import { PageTitle } from './PageTitle'
import { Spacer } from './Spacer'

export interface AddressFormProps {
	action: 'create' | 'edit'
	editedAddress?: AddressFormInputData
	title: string
	onSubmit: (data: AddressFormInputData) => void
}

export const AddressForm: FunctionComponent<AddressFormProps> = ({
	action,
	editedAddress,
	title,
	onSubmit,
}) => {
	const [data, setData] = useState<AddressFormInputData>(
		editedAddress || {
			name: '',
			fullName: '',
			email: '',
			phone: '',
			raw: '',
			setIsDefaultDeliveryAddress: false,
		}
	)

	return (
		<>
			<PageTitle>{title}</PageTitle>
			<Spacer initial={24} />
			<form
				className={style.wrapper}
				onSubmit={(event) => {
					event.preventDefault()
					const validatedData = validateZod(data, AddressFormInput())

					onSubmit(validatedData)
				}}>
				<Item fullWidth>
					<Input
						name="name"
						label="Pojmenování adresy"
						type="text"
						placeholder="doma, kancl, babička"
						value={data?.name}
						onChange={(event) => setData({ ...data, name: event.target.value })}
					/>
				</Item>
				<Item fullWidth>
					<Input
						name="fullName"
						label="Jméno příjemce"
						type="text"
						autoComplete="name"
						value={data?.fullName}
						onChange={(event) => setData({ ...data, fullName: event.target.value })}
					/>
				</Item>
				<Item>
					<Input
						name="email"
						label="E-mail"
						type="email"
						autoComplete="email"
						value={data?.email}
						onChange={(event) => setData({ ...data, email: event.target.value })}
					/>
				</Item>
				<Item>
					<Input
						name="phone"
						label="Telefon"
						type="text"
						autoComplete="tel"
						value={data?.phone}
						onChange={(event) => setData({ ...data, phone: event.target.value })}
						required
					/>
				</Item>
				<Item fullWidth>
					<Input
						name="raw"
						label="Adresa"
						type="text"
						textarea
						value={data?.raw}
						placeholder="Napříkladová 123, Praha"
						onChange={(event) => setData({ ...data, raw: event.target.value })}
					/>
				</Item>
				{action === 'edit' && (
					<Item fullWidth>
						<Spacer initial={20} />
						<label>
							<input
								type="checkbox"
								name="defaultDeliveryAddress"
								checked={data.setIsDefaultDeliveryAddress}
								onChange={(event) =>
									setData({ ...data, setIsDefaultDeliveryAddress: event.target.checked })
								}
							/>
							Použít jako výchozí <strong>doručovací</strong> adresu
						</label>
					</Item>
				)}
				<Item fullWidth>
					<Spacer initial={10} />
					<Button variant="primary" size="large" type="submit" fullWidth>
						{action === 'create' ? 'Přidat' : 'Upravit'}
					</Button>
				</Item>
			</form>
		</>
	)
}

const Item: FunctionComponent<{
	fullWidth?: boolean
	forceNextLine?: boolean
	children: ReactNode
}> = ({ fullWidth, forceNextLine, children }) => (
	<div
		className={clsx(
			style.item,
			fullWidth && style.view_fullWidth,
			forceNextLine && style.view_forceNextLine
		)}>
		{children}
	</div>
)
