import { isDefined } from '@goodlok/react-sdk'
import { useCurrentLocale, useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import type { ProductAvailability } from '@goodlok/sdk/generated/content_role_customer'
import { ErrorMessage, FormattedCents, Input, RadioMark } from '@goodlok/ui'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { BusinessCategoryResult } from '../data/BusinessCategoryFragment'
import { getAllergensFromIngredients } from '../utils/getAllergensFromIngredients'
import style from './SpecificProductsPicker.module.sass'
import type { ProductQuantities } from './TabbedSpecificProductPickers'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Typesctipt doesn't support ListFormat yet
const { ListFormat } = Intl

export type SpecificProductsPickerProps = {
	categoryId: string
	items: NonNullable<BusinessCategoryResult['specificProducts']>['items']
	quantities: ProductQuantities
	setQuantities: (transition: (oldQuantities: ProductQuantities) => ProductQuantities) => void
	showRecipeImages: boolean
	showPackingImages: boolean
}

type Product = NonNullable<SpecificProductsPickerProps['items'][number]['product']>
type Packing = NonNullable<Product['packing']>
type RecipeProduct = {
	id: string
	packing: Packing
	availability: ProductAvailability
	stocks: number
	availableQuantity: number
	defaultQuantity: number
	priceCents: number
}

const mergeProductsByRecipe = (items: SpecificProductsPickerProps['items']) => {
	const recipes = items.reduce<{
		[recipeId: string]: {
			recipe: NonNullable<Product['recipe']>
			products: Array<RecipeProduct>
		}
	}>((recipes, item) => {
		const { product } = item
		if (!product || !product.recipe || !product.packing) {
			return recipes
		}
		const { recipe, packing } = product
		const newRecipes = { ...recipes }
		if (!(recipe.id in newRecipes)) {
			newRecipes[recipe.id] = {
				recipe: recipe,
				products: [],
			}
		}
		recipe
		newRecipes[recipe.id].products.push({
			id: product.id,
			packing,
			availableQuantity: product.meta?.availableQuantity ?? 0,
			availability: product.availability,
			stocks:
				product.stocks.length > 0
					? product.stocks
							.map((stock) => stock.quantity)
							.reduce((stockA, stockB) => stockA + stockB)
					: 0,
			defaultQuantity: item.defaultQuantityForOrder || 10,
			priceCents: product.customerPricesByCustomer?.priceCents || product.priceCents,
		})

		return Object.fromEntries(
			Object.entries(newRecipes).map(([recipeId, recipe]) => {
				return [
					recipeId,
					{
						...recipe,
						products: recipe.products.sort(
							(productA, productB) =>
								(productA.packing.volumeMl ?? 0) - (productB.packing.volumeMl ?? 0)
						),
					},
				]
			})
		)
	}, {})
	return recipes
}

export const SpecificProductsPicker: FunctionComponent<SpecificProductsPickerProps> = ({
	categoryId,
	items,
	quantities,
	setQuantities,
	showRecipeImages,
	showPackingImages,
}) => {
	const mergedProductsByRecipe = useMemo(
		() =>
			Object.entries(mergeProductsByRecipe(items)).sort(([_idA, recipeA], [_idB, recipeB]) => {
				const allProductsSoldOutA = recipeA.products.every(
					(product) => product.availableQuantity <= 0
				)
					? 0
					: 1
				const allProductsSoldOutB = recipeB.products.every(
					(product) => product.availableQuantity <= 0
				)
					? 0
					: 1

				return allProductsSoldOutB - allProductsSoldOutA
			}),
		[items]
	)
	const setQuantity = useCallback(
		(productId: string, quantity: number) => {
			setQuantities((quantities) => ({
				...quantities,
				[categoryId]: { ...quantities[categoryId], [productId]: quantity },
			}))
		},
		[categoryId, setQuantities]
	)

	return (
		<ul className={style.wrapper}>
			{mergedProductsByRecipe.map(([id, recipe]) => (
				<SpecificProductsPickerItem
					categoryId={categoryId}
					key={id}
					recipe={recipe}
					setQuantity={setQuantity}
					quantities={quantities}
					showRecipeImages={showRecipeImages}
					showPackingImages={showPackingImages}
				/>
			))}
		</ul>
	)
}

const SpecificProductsPickerItem: FunctionComponent<{
	categoryId: string
	recipe: ReturnType<typeof mergeProductsByRecipe>[string]
	quantities: SpecificProductsPickerProps['quantities']
	setQuantity: (id: string, quantity: number) => void
	showRecipeImages: boolean
	showPackingImages: boolean
}> = ({
	categoryId,
	recipe: { recipe, products },
	setQuantity,
	quantities,
	showRecipeImages,
	showPackingImages,
}) => {
	const translate = useTranslate()
	const [rawQuantityInput, setRawQuantityInput] = useState('')

	const rawQuantityInputNumber = useMemo(
		() => Math.max(0, Math.round(parseFloat(rawQuantityInput)) || 0),
		[rawQuantityInput]
	)

	const [selectedProduct, setSelectedProduct] = useState<null | RecipeProduct>(null)
	const lastRawQuantityInputNumber = useRef(rawQuantityInputNumber)
	const lastSelectedProduct = useRef(selectedProduct)
	const lastQuantities = useRef(quantities)

	const isPicked = rawQuantityInputNumber > 0 && selectedProduct !== null

	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (lastSelectedProduct.current === null && selectedProduct !== null) {
			setRawQuantityInput(selectedProduct.defaultQuantity.toString())
		}
	}, [selectedProduct])

	useEffect(() => {
		if (selectedProduct !== lastSelectedProduct.current && lastSelectedProduct.current !== null) {
			setQuantity(lastSelectedProduct.current.id, 0)
		}
		lastSelectedProduct.current = selectedProduct
		if (selectedProduct !== null) {
			setQuantity(selectedProduct.id, rawQuantityInputNumber)
		}
	}, [selectedProduct, rawQuantityInputNumber, setQuantity])

	useEffect(() => {
		lastRawQuantityInputNumber.current = rawQuantityInputNumber
	}, [rawQuantityInputNumber])

	useEffect(() => {
		lastQuantities.current = quantities
	}, [quantities])

	useEffect(() => {
		const selectedProduct = lastSelectedProduct.current
		const rawQuantityInputNumber = lastRawQuantityInputNumber.current
		const selectedProductQuantity =
			selectedProduct !== null && selectedProduct.id in quantities
				? quantities[categoryId][selectedProduct.id]
				: null
		if (selectedProductQuantity === null) {
			return
		}
		if (rawQuantityInputNumber !== selectedProductQuantity) {
			setRawQuantityInput(selectedProductQuantity > 0 ? `${selectedProductQuantity}` : '')
		}
	}, [categoryId, quantities])

	const locale = useCurrentLocale()
	const conjuntionFormatter = useMemo(
		() =>
			new ListFormat(locale, {
				style: 'long',
				type: 'conjunction',
			}),
		[locale]
	)

	const allergens = getAllergensFromIngredients(
		recipe.ingredients.map((recipeIngredient) => recipeIngredient.ingredient).filter(isDefined)
	)

	const allProductsSoldOut = products.every((product) => product.availableQuantity <= 0)

	return (
		<li
			className={clsx(
				style.item,
				isPicked && style.is_picked,
				showRecipeImages && style.is_withRecipeImage
			)}>
			<button
				className={style.quickPick}
				type="button"
				onClick={() => {
					const product = products.find((product) => product.availableQuantity > 0)
					if (!selectedProduct && product) {
						setSelectedProduct(product)
						requestAnimationFrame(() => {
							inputRef.current?.focus()
						})
					} else {
						setSelectedProduct(null)
						setRawQuantityInput('')
					}
				}}></button>
			{showRecipeImages && (
				<div className={style.image}>
					{recipe.icon && (
						<Image
							src={recipe.icon.url}
							layout="fill"
							objectFit="contain"
							objectPosition="top"
							sizes="24px"
							alt={recipe.icon.alt ?? ''}
						/>
					)}
				</div>
			)}
			<div className={style.about}>
				<div className={style.title}>
					<h3 className={style.name}>{recipe.localesByLocale?.name}</h3>
					{allProductsSoldOut && (
						<div className={style.error}>
							<ErrorMessage error="Vyprodáno" />
						</div>
					)}
				</div>
				<div className={style.description}>
					{recipe.localesByLocale?.description}{' '}
					{conjuntionFormatter.format(
						recipe.ingredients
							.map(({ ingredient }) => ingredient?.localesByLocale?.name.toLowerCase())
							.filter(isDefined)
					)}
					{allergens.length > 0 && `. ${translate('recipe.allergens')} `}
					{conjuntionFormatter.format(
						allergens.map(
							({ code, localesByLocale }) => `${code}-${localesByLocale?.name?.toLowerCase()}`
						)
					)}
				</div>
			</div>
			<div className={style.controls}>
				<div className={style.packings}>
					{products.map((product) => (
						<label
							className={clsx(style.packing, product.availableQuantity <= 0 && style.is_disabled)}
							key={product.id}>
							<input
								className={style.packing_input}
								type="radio"
								checked={selectedProduct?.id === product.id}
								onChange={(event) => {
									if (event.target.checked) {
										setSelectedProduct(product)
										requestAnimationFrame(() => {
											inputRef.current?.focus()
										})
									}
								}}
								disabled={product.availableQuantity <= 0}
							/>
							<RadioMark />
							<div className={style.packing_detail}>
								{showPackingImages && (
									<div className={style.packing_icon}>
										{product.packing.icon && (
											<Image
												src={product.packing.icon.url}
												layout="fill"
												objectFit="contain"
												objectPosition="bottom"
												sizes="40px"
												alt={product.packing.icon.alt ?? ''}
											/>
										)}
									</div>
								)}
								<div className={style.packing_name}>
									{product.packing.localesByLocale?.shortName ??
										product.packing.localesByLocale?.name}{' '}
									- <FormattedCents cents={product.priceCents} />
									{!allProductsSoldOut && product.availableQuantity <= 0 && (
										<div className={style.availability}>Vyprodáno</div>
									)}
								</div>
							</div>
						</label>
					))}
				</div>
				<div className={clsx(style.quantity, selectedProduct === null && style.is_disabled)}>
					<Input
						ref={inputRef}
						disabled={selectedProduct === null}
						label="ks"
						labelPosition="after"
						type="number"
						value={rawQuantityInput}
						min={0}
						onChange={(event) => {
							setRawQuantityInput(event.target.value)
						}}
						onWheel={(event) => {
							event.currentTarget.blur()
						}}
						error={
							selectedProduct &&
							selectedProduct.availability === 'stockDependent' &&
							rawQuantityInputNumber > selectedProduct.availableQuantity
								? translate('order.specificProducts.maxQuantity').replaceAll(
										'{quantity}',
										selectedProduct.stocks.toString()
								  )
								: undefined
						}
					/>
				</div>
			</div>
		</li>
	)
}
