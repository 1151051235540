import { useGoodlokAuth, useGoodlokCustomerId } from '@goodlok/react-sdk'
import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { CustomerFragment } from '../data/CustomerFragment'

export function useCustomerId() {
	const customerId = useGoodlokCustomerId()
	return customerId
}

export function useCustomerInfo() {
	const auth = useGoodlokAuth()
	const customerId = useGoodlokCustomerId()

	const query = useQuery(['customerInfo', customerId as string] as const, async (q) => {
		const id = q.queryKey[1]
		const customer = await auth.zeus.roleCustomer('query')({
			getCustomer: [{ by: { id } }, CustomerFragment()],
		})

		return customer.getCustomer
	})

	return {
		isLoading: query.isLoading,
		isAnonymous: Boolean(query.data?.anonymousSession?.id && !query.data?.user?.id),
		customer: query.data,
		isCustomerBusinessMemberOrOwner: Boolean(
			query.data?.memberOfBusinesses[0]?.id ||
				query.data?.ownerOfBusinesses[0]?.id ||
				query.data?.business?.id
		),
		remainingCredits:
			query.data?.ownerOfBusinesses[0]?.customer?.meta?.remainingCreditsCents ||
			query.data?.memberOfBusinesses[0]?.customer?.meta?.remainingCreditsCents ||
			query.data?.meta?.remainingCreditsCents,
	}
}

export function useBusinessCustomerId() {
	const auth = useGoodlokAuth()
	const id = useGoodlokCustomerId()

	const query = useQuery(
		['businessCustomerId', id as string] as const,
		async (q) => {
			const id = q.queryKey[1]
			const customer = await auth.zeus.roleCustomer('query')({
				getCustomer: [
					{ by: { id } },
					{
						memberOfBusinesses: [
							{},
							{
								customer: [
									{},
									{
										id: true,
									},
								],
							},
						],
						ownerOfBusinesses: [
							{},
							{
								customer: [
									{},
									{
										id: true,
									},
								],
							},
						],
					},
				],
			})

			return (
				customer.getCustomer?.memberOfBusinesses[0]?.customer?.id ||
				customer.getCustomer?.ownerOfBusinesses[0]?.customer?.id ||
				id
			)
		},
		{
			enabled: Boolean(id),
		}
	)

	useMirrorLoading(query.isFetching)

	return query.data
}
