import type { FunctionComponent, MouseEventHandler } from 'react'
import style from './SideMenuCloser.module.sass'

export type SideMenuCloserProps = {
	onClick: MouseEventHandler<HTMLButtonElement>
}

export const SideMenuCloser: FunctionComponent<SideMenuCloserProps> = ({ onClick }) => {
	return (
		<button className={style.wrapper} type="button" aria-label="zavřít menu" onClick={onClick} />
	)
}
