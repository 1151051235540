import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { customerZeus } from '../data/Zeus/client/customer'
import { useBusinessCustomerId } from '../hooks/useCustomer'

export function usePeopleCount() {
	const businessCustomerId = useBusinessCustomerId()

	const query = useQuery(
		['peopleCount', businessCustomerId],
		async () => {
			return customerZeus('query')({
				paginateCustomer: [
					{
						filter: {
							or: [
								{ ownerOfBusinesses: { customer: { id: { eq: businessCustomerId } } } },
								{ memberOfBusinesses: { customer: { id: { eq: businessCustomerId } } } },
							],
						},
					},
					{
						pageInfo: {
							totalCount: true,
						},
					},
				],
			}).then((data) => data.paginateCustomer?.pageInfo?.totalCount)
		},
		{
			enabled: Boolean(businessCustomerId),
		}
	)
	useMirrorLoading(query.isFetching)

	return query.data
}

export function useListPeople() {
	const businessCustomerId = useBusinessCustomerId()
	const query = useQuery(
		['people', businessCustomerId],
		async () => {
			return customerZeus('query')({
				listCustomer: [
					{
						filter: {
							or: [
								{ ownerOfBusinesses: { customer: { id: { eq: businessCustomerId } } } },
								{ memberOfBusinesses: { customer: { id: { eq: businessCustomerId } } } },
							],
						},
					},
					{
						id: true,
						email: true,
						meta: [
							{},
							{
								name: true,
								fallbackName: true,
							},
						],
					},
				],
			}).then((data) => data.listCustomer)
		},
		{
			enabled: Boolean(businessCustomerId),
		}
	)
	useMirrorLoading(query.isFetching)

	return query
}
