import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import { Tabs } from '@goodlok/ui'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useOrderProductQuantitiesState } from '../states/order'
import type { SpecificProductsProductCategories } from './OrderPage'
import { SpecificProductsPicker } from './SpecificProductsPicker'

export type TabbedSpecificProductPickersProps = {
	categories?: NonNullable<SpecificProductsProductCategories>['categories']
}

export type ProductQuantities = {
	[categoryId: string]: {
		[productId: string]: number
	}
}

export const TabbedSpecificProductPickers: FunctionComponent<TabbedSpecificProductPickersProps> = ({
	categories,
}) => {
	const translate = useTranslate()
	const initialize = useCallback(() => {
		const result: ProductQuantities = {}
		categories?.forEach((category) => {
			if (!(category.id in result)) {
				result[category.id] = {}
			}
			category.products.forEach((product) => {
				if (product.product) {
					result[category.id][product.product.id] = 0
				}
			})
		})
		return result
	}, [categories])
	const [quantities, setQuantities] = useOrderProductQuantitiesState()

	useEffect(() => {
		setQuantities(initialize)
	}, [initialize, setQuantities])

	return categories ? (
		categories.length > 1 ? (
			<Tabs
				items={categories.map((category) => ({
					label: category.name,
					content: (
						<SpecificProductsPicker
							categoryId={category.id}
							items={category.products}
							setQuantities={setQuantities}
							quantities={quantities}
							showRecipeImages={category.showRecipeImages}
							showPackingImages={category.showPackingImages}
						/>
					),
				}))}
			/>
		) : (
			<SpecificProductsPicker
				categoryId={categories[0].id || ''}
				items={categories[0].products || []}
				setQuantities={setQuantities}
				quantities={quantities}
				showRecipeImages={categories[0].showRecipeImages}
				showPackingImages={categories[0].showPackingImages}
			/>
		)
	) : (
		<>{translate('specificProducts.noCategory')}</>
	)
}
