import fetch from 'isomorphic-fetch'
import type { NextApiRequest, NextApiResponse } from 'next'
import { ZodError } from 'zod'
type RepoOf<T> = {
	[K in keyof T]: T[K] extends IApiAction<infer I, infer O> ? IApiAction<I, O> : never
}
type InputsOf<T> = {
	[K in keyof T]: T[K] extends IApiAction<infer I, infer _> ? I : never
}
type OutputsOf<T> = {
	[K in keyof T]: T[K] extends IApiAction<infer _, infer O> ? O : never
}
type BaseInput = Record<string | number, unknown>
interface IApiAction<Input extends BaseInput, Output> {
	validate: (data: Input) => Input
	run: (data: Input, req: NextApiRequest, res: NextApiResponse) => Promise<Output>
}
export class ApiAction<Input extends BaseInput, Output> implements IApiAction<Input, Output> {
	public validate: (data: Input) => Input
	constructor(
		validate: ((data: Input) => Input) | { parse: (data: Input) => Input },
		public run: (data: Input, req: NextApiRequest, res: NextApiResponse) => Promise<Output>
	) {
		if ('parse' in validate) {
			this.validate = (data) => validate.parse(data)
		} else {
			this.validate = (data) => validate(data)
		}
	}
}
async function handleApiAction<Input extends BaseInput, Output>(
	action: IApiAction<Input, Output>,
	input: Input,
	req: NextApiRequest,
	res: NextApiResponse
) {
	const validInput = action.validate(input)
	return action.run(validInput, req, res)
}
export function zodErrorInfo(e: ZodError) {
	return {
		flat: e.flatten(),
		issues: e.issues,
		errors: e.errors,
	}
}
export type ValidationErrorsInfo = ReturnType<typeof zodErrorInfo>
function validationInputFromRequest<R extends Partial<NextApiRequest>>(req: R) {
	return { ...(req.body ?? {}), _req: req }
}
export class NextCloseResponse {}
export function createJsonApiHandler<T, R>(repository: R extends RepoOf<T> ? R : never) {
	return async function handler(req: NextApiRequest, res: NextApiResponse) {
		const handler = req.query.handler as keyof typeof repository
		const apiAction = repository[handler]
		if (apiAction) {
			try {
				const result = await handleApiAction(apiAction, validationInputFromRequest(req), req, res)
				res.status(200).json(result)
				return
			} catch (e) {
				if (e instanceof ZodError) {
					res.status(400).json({
						status: 'error',
						validationErrors: zodErrorInfo(e),
					})
					return
				} else if (e instanceof NextCloseResponse) {
					return
				} else {
					throw e
				}
			}
		}
		throw new Error(`Invalid API action handler '${String(handler)}'.`)
	}
}
type ErrorResponse = { status: 'error' }
type ValidationErrorResponse = { validationErrors: ValidationErrorsInfo }
type BasicErrorResponse = { message: string }
export type PossibleErrorResponse = ErrorResponse & (ValidationErrorResponse | BasicErrorResponse)
export class ValidationError extends Error {
	public constructor(
		public original: PossibleErrorResponse,
		public source?: string
	) {
		super()
		this.message = this.getDeveloperMessage()
	}
	public getDeveloperMessage() {
		const prefix = this.source ? `[${this.source}] ` : ''
		if ('message' in this.original) {
			return prefix + this.original.message
		}
		if ('validationErrors' in this.original) {
			return (
				prefix +
				this.original.validationErrors.issues
					.map((e) => `[${e.path.join('.')}] ${e.message} (${e.code}).`)
					.join('\n')
			)
		}
		return prefix + 'ValidationError'
	}
	public getUserMessage() {
		return this.getDeveloperMessage()
	}
	public getFormErrors(): FormValidationErrors {
		if ('validationErrors' in this.original) {
			return { ...this.original.validationErrors.flat, source: this.source }
		}
		return {
			source: this.source,
			formErrors: [this.getUserMessage()],
			fieldErrors: {},
		}
	}
}
export function createFetcher<T>(url: string) {
	type Repo = RepoOf<T>
	type Inputs = InputsOf<T>
	type Outputs = OutputsOf<T>
	return async function fetcher<Key extends keyof Repo>(
		key: Key,
		input: Inputs[Key]
	): Promise<Outputs[Key]> {
		const actionUrl = url.replace('[handler]', String(key))
		let searchQuery = ''
		const { _req, ...body } = input as typeof input & { _req: Partial<NextApiRequest> }
		const req = _req ?? {}
		if (req.query) {
			const s = new URLSearchParams()
			Object.entries(req.query).forEach((entry) => {
				const list = Array.isArray(entry[1]) ? entry[1] : [entry[1]]

				list.forEach((value) => {
					s.append(entry[0], String(value))
				})
			})
			searchQuery = s.toString()
			searchQuery = searchQuery ? `?${searchQuery}` : ''
		}
		const hasBody = Object.keys(body).length > 0
		console.log(req.method, hasBody, req.method ?? hasBody ? 'POST' : 'GET')
		const request = await fetch(actionUrl + searchQuery, {
			method: req.method ?? hasBody ? 'POST' : 'GET',
			body: hasBody ? JSON.stringify(body) : undefined,
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				Accept: 'application/json;charset=utf-8',
			},
		})
		const result = (await request.json()) as unknown as PossibleErrorResponse | { status: 'ok' }
		if (result && 'status' in result && result.status === 'error') {
			throw new ValidationError(result, 'server')
		}
		const validResult = result as unknown as Outputs[Key]
		return validResult
	}
}
export type FormValidationErrors = ReturnType<ZodError['flatten']> & { source?: string }
