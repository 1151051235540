import type { FunctionComponent } from 'react'
import styles from './SuccessBox.module.sass'

export type SuccessBoxProps = {
	title?: string
	description?: string
}

export const SuccessBox: FunctionComponent<SuccessBoxProps> = ({ title, description }) => {
	return (
		<div className={styles.wrapper}>
			{title && <h2 className={styles.title}>{title}</h2>}
			{description && <p className={styles.description}>{description}</p>}
		</div>
	)
}
