import { GoodlokAuthStatus, Modal, useAppFrameContext, useGoodlokAuth } from '@goodlok/react-sdk'
import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import { SharedLoadingIndicator } from '@goodlok/ui'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { useCustomerInfo } from '../hooks/useCustomer'
import { HamburgerMenuOpener } from './HamburgerMenuOpener'
import style from './Layout.module.sass'
import { SideMenu } from './SideMenu'
import { SideMenuCloser } from './SideMenuCloser'
import { SignIn } from './SignIn'
import { SuccessBox } from './SuccessBox'

export const Layout: FunctionComponent<
	PropsWithChildren<{
		requiresAuthentication?: boolean
	}>
> = ({ children, requiresAuthentication = true }) => {
	const auth = useGoodlokAuth()
	const appFrame = useAppFrameContext()
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

	const [signingIn, setSigningIn] = useState(false)
	const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)

	const {
		isLoading: isCustomerInfoLoading,
		isCustomerBusinessMemberOrOwner,
		isAnonymous,
	} = useCustomerInfo()
	const translate = useTranslate()

	useEffect(() => {
		setIsSignInModalOpen(
			(requiresAuthentication || signingIn) &&
				(auth.status === GoodlokAuthStatus.SignedOut || isAnonymous)
		)
	}, [auth.status, isAnonymous, requiresAuthentication, signingIn])

	useEffect(() => {
		appFrame?.toggle(isSideMenuOpen)
	}, [appFrame, isSideMenuOpen])

	const router = useRouter()
	useEffect(() => {
		const handleRouteChange = () => {
			setIsSideMenuOpen(false)
			appFrame?.close()
		}
		router.events.on('routeChangeStart', handleRouteChange)

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	}, [appFrame, router.events])

	return (
		<div className={clsx(style.wrapper, isSideMenuOpen && style.is_isSideMenuOpen)}>
			<SharedLoadingIndicator />
			<button
				type="button"
				aria-label="zavřít menu"
				onClick={() => {
					setIsSideMenuOpen(false)
				}}
				className={style.sideMenu_background}
			/>
			<header className={style.sideMenu}>
				<div className={style.sideMenu_content}>
					<div className={style.closer}>
						<SideMenuCloser
							onClick={() => {
								setIsSideMenuOpen(false)
							}}
						/>
					</div>
					<SideMenu
						onSignInButtonClick={() => {
							setSigningIn(true)
						}}
					/>
				</div>
			</header>
			<main className={style.main}>
				<div className={style.mainIn}>
					<Modal
						isOpen={isSignInModalOpen}
						onRequestClose={() => {
							if (!requiresAuthentication) {
								setSigningIn(false)
								setIsSignInModalOpen(false)
							}
						}}>
						<SignIn />
					</Modal>
					<div className={style.opener}>
						<HamburgerMenuOpener
							onClick={() => {
								setIsSideMenuOpen(true)
							}}
						/>
					</div>

					{!requiresAuthentication || isCustomerBusinessMemberOrOwner ? (
						children
					) : isCustomerInfoLoading ? (
						<></>
					) : (
						<SuccessBox
							title={translate('messages.thankYouForSigningIn')}
							description={translate('messages.noRelatedBusinessCustomer')}
						/>
					)}
				</div>
			</main>
		</div>
	)
}
