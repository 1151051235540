import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { ImageFragment } from './ImageFragment'
import { SpecificProductFragment } from './SpecificProductFragment'
export const BusinessCategoryFragment = (locale: string, customerId?: string) =>
	Selector('BusinessCategory')({
		id: true,
		internalName: true,
		localesByLocale: [
			{ by: { locale: { identifier: { code: locale } } } },
			{
				name: true,
				title: true,
			},
		],
		recurrences: [
			{
				orderBy: [{ order: 'asc' }],
			},
			{
				id: true,
				localesByLocale: [
					{
						by: { locale: { identifier: { code: locale } } },
					},
					{
						name: true,
					},
				],
			},
		],
		seasonalProductCategories: [
			{},
			{
				localesByLocale: [
					{
						by: { locale: { identifier: { code: locale } } },
					},
					{
						name: true,
					},
				],
				items: [
					{
						orderBy: [{ order: 'asc' }],
					},
					{
						id: true,
						productCategory: [
							{},
							{
								id: true,
								name: true, // @TODO: use translated field/locale
								image: [{}, ImageFragment()],
							},
						],
					},
				],
			},
		],
		specificProductsProductCategories: [
			{},
			{
				localesByLocale: [
					{
						by: { locale: { identifier: { code: locale } } },
					},
					{
						name: true,
					},
				],
				items: [
					{ orderBy: [{ order: 'asc' }] },
					{
						id: true,
						productCategory: [
							{},
							{
								id: true,
								name: true,
								products: [
									{
										filter: {
											and: [
												{
													availability: { in: ['available', 'stockDependent'] },
												},
												customerId
													? {
															customerPrices: {
																customer: { id: { eq: customerId } },
																offered: { eq: true },
															},
													  }
													: { id: { isNull: false } },
											],
										},
									},
									SpecificProductFragment(locale, customerId),
								],
							},
						],
						showAllAvailableProducts: true,
						showPackingImages: true,
						showRecipeImages: true,
					},
				],
			},
		],
		specificProducts: [
			{},
			{
				items: [
					{ orderBy: [{ order: 'asc' }] },
					{
						id: true,
						defaultQuantityForOrder: true,
						product: [
							{
								filter: {
									and: [
										{
											meta: {
												available: { eq: true },
											},
										},
										customerId
											? {
													customerPrices: {
														customer: { id: { eq: customerId } },
														offered: { eq: true },
													},
											  }
											: { id: { isNull: false } },
									],
								},
							},
							SpecificProductFragment(locale, customerId),
						],
					},
				],
			},
		],
	})
export type BusinessCategoryResult = InputType<
	GraphQLTypes['BusinessCategory'],
	ReturnType<typeof BusinessCategoryFragment>
>
