import type { AllergenResult } from '../data/AllergenFragment'
import type { IngredientResult } from '../data/IngredientFragment'

export function getAllergensFromIngredients(ingredients: IngredientResult[]) {
	const allergens: AllergenResult[] = []
	ingredients.forEach((ingredient) => {
		ingredient.allergens.forEach((allergen) => {
			if (!allergens.includes(allergen)) {
				allergens.push(allergen)
			}
		})
	})
	return allergens.sort((a, b) => a.code - b.code)
}
