import { useGoodlokShopping } from '@goodlok/react-sdk'
import { useEffect } from 'react'
import { z } from 'zod'
import type { ProductQuantities } from '../components/TabbedSpecificProductPickers'
import { useOrderProductQuantitiesState } from '../states/order'

const ProductQuantities = z.record(z.string(), z.record(z.string(), z.number()))

export function useProductQuantitiesCart() {
	const [quantities] = useOrderProductQuantitiesState()
	const shopping = useGoodlokShopping()

	const { setItems } = shopping

	const serialized = JSON.stringify(quantities)

	useEffect(() => {
		console.log({ serialized })
		const unserialized = ProductQuantities.parse(JSON.parse(serialized))
		console.log({ unserialized })

		const items: Record<string, number> = {}

		Object.values(unserialized).forEach((productsRepo) => {
			Object.entries(productsRepo).forEach(([id, quantity]) => {
				if (quantity) {
					items[id] = (items[id] ?? 0) + quantity
				}
			})
		})

		const update = Object.entries(items).map(([productId, quantity]) => ({ productId, quantity }))
		console.log({ update })
		setItems({ items: update })
	}, [serialized, setItems])

	return shopping
}
