import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import type { ProductQuantities } from '../components/TabbedSpecificProductPickers'

export const orderProductQuantitiesState = atom<ProductQuantities>({
	key: 'orderProductQuantities',
	default: {},
})

export const orderBillingAddressState = atom<string | null>({
	key: 'orderProductBillingAddress',
	default: null,
})

export const orderDeliveryAddressState = atom<string | null>({
	key: 'orderProductDeliveryAddress',
	default: null,
})

export const getOrderProductsAggregateQuantity = (quantities: ProductQuantities) => {
	let sum = 0
	Object.values(quantities).forEach((item) => {
		const initialQuantity = 0
		sum += Object.values(item).reduce((previous, current) => previous + current, initialQuantity)
	})
	return sum
}

export const useResetOrderState = () => {
	const setProductQuantitiesState = useRecoilState(orderProductQuantitiesState)[1]
	const setOrderBillingAddressState = useRecoilState(orderBillingAddressState)[1]
	const setDeliveryAddressState = useRecoilState(orderDeliveryAddressState)[1]
	return useCallback(() => {
		setProductQuantitiesState({})
		setOrderBillingAddressState(null)
		setDeliveryAddressState(null)
	}, [setDeliveryAddressState, setOrderBillingAddressState, setProductQuantitiesState])
}

export const useOrderProductQuantitiesState = () => {
	return useRecoilState<ProductQuantities>(orderProductQuantitiesState)
}
