import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'

export const AllergenFragment = (locale: string) =>
	Selector('Allergen')({
		code: true,
		localesByLocale: [
			{
				by: { locale: { identifier: { code: locale } } },
			},
			{
				name: true,
			},
		],
	})

export type AllergenResult = InputType<
	GraphQLTypes['Allergen'],
	ReturnType<typeof AllergenFragment>
>
