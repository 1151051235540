import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { BusinessCategoryResult } from '../data/BusinessCategoryFragment'
import style from './CategoriesPicker.module.sass'

export type CategoriesPickerProps = {
	items: NonNullable<BusinessCategoryResult['seasonalProductCategories']>['items']
}

export const CategoriesPicker: FunctionComponent<CategoriesPickerProps> = ({ items }) => {
	return (
		<ul className={style.wrapper}>
			{items.map(
				(category) =>
					category.productCategory && (
						<li key={category.id} className={style.item}>
							<label className={style.label}>
								<input
									type="checkbox"
									className={style.input}
									// @TODO: maybe add name prop
									value={category.productCategory.id}
									onChange={(event) => {
										console.log('@TODO: handle change', event.target.checked)
									}}
								/>
								<div className={style.content}>
									<span className={style.mark} />
									<div className={style.image}>
										{category.productCategory.image && (
											<Image
												src={category.productCategory.image.url}
												layout="fill"
												objectFit="contain"
												sizes="190px"
												alt={category.productCategory.image.alt ?? ''}
											/>
										)}
									</div>
									<div className={style.text}>{category.productCategory.name}</div>
								</div>
							</label>
						</li>
					)
			)}
		</ul>
	)
}
