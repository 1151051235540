import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { Switch } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import type { BusinessCategoryResult } from '../data/BusinessCategoryFragment'
import { SectionHeader } from './SectionHeader'

export type BusinessCategorySelectProps = {
	businessCategories: BusinessCategoryResult[]
	selectedBusinessCategoryId: string | null
	onChange: (value: string | null) => void
}

export const BusinessCategorySelect: FunctionComponent<BusinessCategorySelectProps> = ({
	businessCategories,
	selectedBusinessCategoryId,
	onChange,
}) => {
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()

	return (
		<>
			<SectionHeader
				title={translate('order.purpose.title')}
				description={translateWithoutFallback('order.purpose.description')}
			/>
			{businessCategories && (
				<Switch
					name="businessCategory"
					selectedValue={selectedBusinessCategoryId}
					onChange={onChange}
					options={businessCategories.map((item) => ({
						value: item.id,
						label: item.localesByLocale?.name ?? '',
					}))}
				/>
			)}
		</>
	)
}
