import {
	FragmentResult,
	ValueTypes,
	ZeusSelect,
} from '@goodlok/sdk/generated/content_role_customer'

export function ImageFragment() {
	return ZeusSelect<ValueTypes['Image']>()({
		id: true,
		url: true,
		width: true,
		height: true,
		alt: true,
		size: true,
		type: true,
	})
}

export type ImageResult = FragmentResult<'Image', typeof ImageFragment>
