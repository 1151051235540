import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { AddressFragment } from './AddressFragment'
import { BillingSubjectFragment } from './BillingSubjectFragment'

export const CustomerAddressesFragment = () =>
	Selector('Customer')({
		addresses: [
			{
				filter: { deletedAt: { isNull: true } },
				orderBy: [{ createdAt: 'desc' }, { name: 'asc' }],
			},
			AddressFragment(),
		],
		billingSubjects: [
			{
				filter: { deletedAt: { isNull: true } },
				orderBy: [{ createdAt: 'desc' }, { name: 'asc' }],
			},
			BillingSubjectFragment(),
		],
	})

export type CustomerAddressesResult = InputType<
	GraphQLTypes['Customer'],
	ReturnType<typeof CustomerAddressesFragment>
>
