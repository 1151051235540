import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { BusinessCategoryFragment } from '../data/BusinessCategoryFragment'
import { customerZeus } from '../data/Zeus/client/customer'
import { publicZeus } from '../data/Zeus/client/public'
import { useBusinessCustomerId } from '../hooks/useCustomer'

export type BusinessCategories = ReturnType<typeof useBusinessCategories>['data']

export function useBusinessCategories(locale: string) {
	const businessCustomerId = useBusinessCustomerId()
	const query = useQuery(['businessCategories', businessCustomerId, locale], async () => {
		return (
			businessCustomerId
				? await customerZeus('query')({
						listBusinessCategory: [{}, BusinessCategoryFragment(locale, businessCustomerId)],
				  })
				: await publicZeus('query')({
						listBusinessCategory: [{}, BusinessCategoryFragment(locale)],
				  })
		).listBusinessCategory.map((category) => {
			return {
				...category,
			}
		})
	})
	useMirrorLoading(query.isFetching)

	return query
}

export function useCustomerBusinessCategory(locale: string) {
	const businessCustomerId = useBusinessCustomerId()
	const query = useQuery(
		['customerDefaultBusinessCategory', businessCustomerId],
		async () => {
			if (businessCustomerId) {
				const data = await customerZeus('query')({
					getCustomer: [
						{ by: { id: businessCustomerId } },
						{
							business: [
								{},
								{
									category: [{}, BusinessCategoryFragment(locale, businessCustomerId)],
								},
							],
						},
					],
				})
				return data.getCustomer?.business?.category ?? null
			}
			return null
		},
		{
			enabled: Boolean(businessCustomerId),
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		}
	)
	useMirrorLoading(query.isFetching)

	return query
}
