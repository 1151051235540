import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'

export const BillingSubjectFragment = () =>
	Selector('BillingSubject')({
		id: true,
		fullName: true,
		addressLine1: true,
		addressLine2: true,
		city: true,
		postalCode: true,
		country: [
			{},
			{
				code: true,
			},
		],
		email: true,
		phone: true,
		companyName: true,
		companyIdentifier: true,
		vatIdentifier: true,
		defaultBillingSubjectOfCustomer: [
			{},
			{
				id: true,
			},
		],
	})

export type BillingSubjectResult = InputType<
	GraphQLTypes['BillingSubject'],
	ReturnType<typeof BillingSubjectFragment>
>
