import { z } from 'zod'

const LOG_LEVEL = Number(process.env.LOG_LEVEL)

type ThunderBase<R> = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fn: (query: string, variables?: Record<string, unknown>) => Promise<any>
) => R

const thunderFetch = async (
	url: string,
	query: string,
	variables?: Record<string, unknown>,
	headers?: Record<string, string>,
	fetchOptions?: Partial<RequestInit>
) => {
	headers = headers ?? {}
	headers['Content-Type'] = 'application/json'
	if (LOG_LEVEL >= 1) {
		console.log({
			url,
			headers,
			variables,
		})
		console.log(query)
	}

	const fetch = global?.fetch ?? (typeof window === 'undefined' ? null : window)?.fetch
	const req = await fetch(url, {
		method: 'POST',
		headers,
		body: JSON.stringify({ query, variables }),
		...fetchOptions,
	})

	const text = await req.text()

	if (LOG_LEVEL >= 1) {
		console.log(text)
	}

	const data = JSON.parse(text)

	if ('errors' in data) {
		const errorsResponse = ContemberErrorResponse.parse(data)
		throw new ContemberError(errorsResponse.errors[0].code, errorsResponse.errors[0].message, {
			query,
			variables,
		})
	}

	if ('data' in data) {
		return data.data
	}

	throw new Error(JSON.stringify(data, null, 2))
}

export const fromThunderWithToken =
	<R>(thunder: ThunderBase<R>, options: { url: string }) =>
	(token: string) =>
		thunder(async (query, variables) =>
			thunderFetch(options.url, query, variables, { Authorization: `Bearer ${token}` })
		)

export const fromThunder = <R>(thunder: ThunderBase<R>, options: { url: string }) =>
	thunder(async (query, variables) =>
		thunderFetch(options.url, query, variables, undefined, {
			mode: 'cors',
			credentials: 'include',
		})
	)

const ContemberErrorResponse = z.object({
	errors: z.array(
		z.object({
			code: z.number().optional(),
			message: z.string(),
			path: z.array(z.union([z.string(), z.number()])).optional(),
			extensions: z
				.object({
					code: z.union([z.string(), z.number()]),
					exception: z.unknown().optional(),
				})
				.optional(),
		})
	),
})

export class ContemberError extends Error {
	constructor(
		public code: number | undefined,
		public errorMessage: string,
		public original: { query: string; variables?: Record<string, unknown> }
	) {
		super(errorMessage)
	}
}
