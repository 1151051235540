import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { ImageFragment } from './ImageFragment'
import { IngredientFragment } from './IngredientFragment'

function dangerouslyCastUndefinedAsOriginal<Original>(condition: boolean, value: Original) {
	if (condition) {
		return value
	}
	return undefined as Original
}

export const SpecificProductFragment = (locale: string, customerId?: string) =>
	Selector('Product')({
		id: true,
		priceCents: true,
		// workaround: Following code returns unexpected type, hence the dangerous cast
		// customerPricesByCustomer: customerId ? [
		// 	{ by: { customer: { id: customerId } } },
		// 	{
		// 		priceCents: true,
		// 		offered: true,
		// 	},
		// ] : undefined,
		customerPricesByCustomer: dangerouslyCastUndefinedAsOriginal(Boolean(customerId), [
			{ by: { customer: { id: customerId } } },
			{
				priceCents: true,
				offered: true,
			},
		]),
		categories: [
			{},
			{
				id: true,
			},
		],
		packing: [
			{},
			{
				volumeMl: true,
				icon: [{}, ImageFragment()],
				localesByLocale: [
					{ by: { locale: { identifier: { code: locale } } } },
					{
						name: true,
						shortName: true,
					},
				],
			},
		],
		recipe: [
			{},
			{
				localesByLocale: [
					{ by: { locale: { identifier: { code: locale } } } },
					{
						name: true,
						description: true,
					},
				],
				id: true,
				icon: [{}, ImageFragment()],
				ingredients: [
					{ orderBy: [{ order: 'asc' }] },
					{
						ingredient: [{}, IngredientFragment(locale)],
					},
				],
			},
		],
		availability: true,
		meta: [
			{},
			{
				availableQuantity: true,
			},
		],
		stocks: [
			{},
			{
				quantity: true,
			},
		],
	})

export type SpecificProductResult = InputType<
	GraphQLTypes['Product'],
	ReturnType<typeof SpecificProductFragment>
>
