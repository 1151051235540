import { Icon } from '@goodlok/react-sdk'
import Image from 'next/legacy/image'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import { useCustomerInfo } from '../hooks/useCustomer'
import styles from './UserInfo.module.sass'

export const UserInfo: FunctionComponent<{ signOutButton: ReactNode }> = ({ signOutButton }) => {
	const { customer } = useCustomerInfo()

	const customerName = useMemo(() => {
		return customer?.name?.nominative || customer?.email
	}, [customer])

	return customer ? (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				{customer.photo ? (
					<Image
						src={customer.photo.url}
						layout="fill"
						objectFit="contain"
						objectPosition="top"
						sizes="50px"
						alt={customer.photo.alt ?? ''}
					/>
				) : (
					<Icon name="friendsOff" />
				)}
			</div>
			<div className={styles.content}>
				<div title={customerName} className={styles.name}>
					{customerName}
				</div>
				<div className={styles.business}>
					{customer.memberOfBusinesses[0]?.customer?.business?.name ||
						customer.ownerOfBusinesses[0]?.customer?.business?.name ||
						null}
				</div>
				<div className={styles.signOut}>{signOutButton}</div>
			</div>
		</div>
	) : null
}
