import { useGoodlokCustomerId } from '@goodlok/react-sdk'
import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { CustomerAddressesFragment } from '../data/CustomerAddressesFragment'
import { customerZeus } from '../data/Zeus/client/customer'

export function useListAddresses() {
	const customerId = useGoodlokCustomerId()
	const query = useQuery(
		['addresses', customerId],
		async () => {
			if (customerId) {
				return customerZeus('query')({
					getCustomer: [
						{ by: { id: customerId } },
						{
							memberOfBusinesses: [
								{},
								{
									customer: [{}, CustomerAddressesFragment()],
								},
							],
							ownerOfBusinesses: [
								{},
								{
									customer: [{}, CustomerAddressesFragment()],
								},
							],
						},
					],
				}).then(
					(data) =>
						data.getCustomer?.memberOfBusinesses[0]?.customer ??
						data.getCustomer?.ownerOfBusinesses[0]?.customer
				)
			}
		},
		{
			enabled: Boolean(customerId),
		}
	)
	useMirrorLoading(query.isFetching)

	return query
}
