import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { customerZeus } from '../data/Zeus/client/customer'
import { useCustomerId } from './useCustomer'

export function useChannelPaymentMethods() {
	const customerId = useCustomerId()
	const query = useQuery(
		['channelPaymentMethods', customerId],
		async () => {
			return customerZeus('query')({
				getChannel: [
					{ by: { code: 'b2b' } },
					{
						payments: [
							{ filter: { method: { enabled: { eq: true } } } },
							{
								method: [
									{},
									{
										id: true,
										name: true,
									},
								],
							},
						],
					},
				],
			}).then((data) => data.getChannel?.payments)
		},
		{
			enabled: Boolean(customerId),
		}
	)
	useMirrorLoading(query.isFetching)

	return query
}
