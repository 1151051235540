import * as z from 'zod'

export const AddressFormInput = () =>
	z.object({
		name: z.string(),
		fullName: z.string(),
		email: z.string().email(),
		phone: z.string().min(9),
		raw: z.string(),
		setIsDefaultDeliveryAddress: z.boolean(),
	})

export type AddressFormInputData = z.infer<ReturnType<typeof AddressFormInput>>
