import type { FunctionComponent, ReactNode } from 'react'
import style from './SectionHeader.module.sass'

export type SectionHeaderProps = {
	action?: ReactNode
	title?: ReactNode
	description?: ReactNode
}

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
	action,
	description,
	title,
}) => {
	return (
		<div className={style.wrapper}>
			<h3 className={style.titleWrapper}>
				<div className={style.title}>{title}</div>
				{action && <div className={style.action}>{action}</div>}
			</h3>
			<div className={style.description}>{description}</div>
		</div>
	)
}
