import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { Switch } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import {
	GeneralPreferenceId,
	SeasonalProductCategories,
	SpecificProductsProductCategories,
} from './OrderPage'
import { SectionHeader } from './SectionHeader'

export type GeneralPreferenceSelectProps = {
	selectedGeneralPreferenceId: GeneralPreferenceId
	seasonalProductCategories?: SeasonalProductCategories
	specificProductsProductCategories?: SpecificProductsProductCategories
	onChange: (value: string | null) => void
}

export const GeneralPreferenceSelect: FunctionComponent<GeneralPreferenceSelectProps> = ({
	selectedGeneralPreferenceId,
	seasonalProductCategories,
	specificProductsProductCategories,
	onChange,
}) => {
	const translate = useTranslate()
	const translateWithoutFallback = useTranslateWithoutFallback()
	return (
		<>
			<SectionHeader
				title={translate('order.preferredSortiment.title')}
				description={translateWithoutFallback('order.preferredSortiment.description')}
			/>
			<Switch
				name="generalPreference"
				selectedValue={selectedGeneralPreferenceId}
				onChange={onChange}
				options={[
					...(seasonalProductCategories === undefined
						? []
						: [
								{
									value: GeneralPreferenceId.Seasonal,
									label: seasonalProductCategories.name,
								},
						  ]),
					...(specificProductsProductCategories === undefined
						? []
						: [
								{
									value: GeneralPreferenceId.Specific,
									label: specificProductsProductCategories.name,
								},
						  ]),
				]}
			/>
		</>
	)
}
