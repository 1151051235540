import { GoodlokAuthStatus, Modal, useGoodlokAuth } from '@goodlok/react-sdk'
import { FormattedCents } from '@goodlok/ui'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { useProductQuantitiesCart } from '../hooks/useProductQuantitiesCart'
import { getOrderProductsAggregateQuantity, useOrderProductQuantitiesState } from '../states/order'
import style from './CurrentOrderSummary.module.sass'
import { OrderButton } from './OrderButton'
import { SignIn } from './SignIn'

export const CurrentOrderSummary: FunctionComponent<{
	productPrices: {
		[id: string]: number
	}
}> = ({ productPrices }) => {
	const auth = useGoodlokAuth()

	const router = useRouter()

	const shopping = useProductQuantitiesCart()
	const [quantities] = useOrderProductQuantitiesState()

	const totalPriceCents = useMemo(() => {
		let sum = 0
		Object.values(quantities).forEach((products) => {
			Object.entries(products).forEach(([id, quantity]) => {
				const productPrice = productPrices[id] ?? 0
				sum += quantity * productPrice
			})
		})
		return sum
	}, [productPrices, quantities])

	const [
		specificProductQuantities,
		setSpecificProductQuantities,
		{ removeItem: removeSpecificProductQuantitiesItem },
	] = useLocalStorageState<null | string>('specificProductQuantities')

	useEffect(() => {
		if (auth.signedIn && specificProductQuantities && shopping.cart.data?.checkoutUrl) {
			removeSpecificProductQuantitiesItem()
			router.push(shopping.cart.data.checkoutUrl)
		}
	}, [
		auth.signedIn,
		removeSpecificProductQuantitiesItem,
		router,
		setSpecificProductQuantities,
		shopping.cart.data?.checkoutUrl,
		specificProductQuantities,
	])
	const [showSignIn, setShowSignIn] = useState(false)

	const itemsCount = useMemo(() => {
		if (shopping.cart.data?.itemsCount) {
			return shopping.cart.data?.itemsCount
		} else {
			return getOrderProductsAggregateQuantity(quantities)
		}
	}, [quantities, shopping.cart.data?.itemsCount])

	if (!itemsCount || itemsCount === 0) {
		return null
	}

	return (
		<div className={style.wrapper}>
			<div className={style.in}>
				<div className={style.summary}>
					<span className={style.main}>{itemsCount} ks položek</span> za{' '}
					<span className={style.price}>
						<FormattedCents cents={totalPriceCents} />
					</span>
					<div className={style.note}>Doprava po Praze zdarma</div>
				</div>
				<div className={style.action}>
					<OrderButton
						onClick={() => {
							if (shopping.cart.data?.checkoutUrl) {
								router.push(shopping.cart.data?.checkoutUrl)
							} else {
								if (!auth.signedIn) {
									setSpecificProductQuantities(JSON.stringify(quantities))
									setShowSignIn(true)
								}
							}
						}}
					/>
					<Modal isOpen={showSignIn && auth.status === GoodlokAuthStatus.SignedOut}>
						<SignIn />
					</Modal>
				</div>
			</div>
		</div>
	)
}
