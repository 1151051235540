import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'

export const AddressFragment = () =>
	Selector('Address')({
		id: true,
		name: true,
		fullName: true,
		firstName: true,
		lastName: true,
		addressLine1: true,
		addressLine2: true,
		city: true,
		phone: true,
		email: true,
		postalCode: true,
		raw: true,
		country: [
			{},
			{
				code: true,
			},
		],
		defaultDeliveryAddressOfCustomer: [
			{},
			{
				id: true,
			},
		],
	})

export type AddressResult = InputType<GraphQLTypes['Address'], ReturnType<typeof AddressFragment>>
