import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_customer'
import { AllergenFragment } from './AllergenFragment'

export const IngredientFragment = (locale: string) =>
	Selector('ProductIngredient')({
		localesByLocale: [
			{ by: { locale: { identifier: { code: locale } } } },
			{
				name: true,
			},
		],
		allergens: [{ orderBy: [{ code: 'asc' }] }, AllergenFragment(locale)],
	})

export type IngredientResult = InputType<
	GraphQLTypes['ProductIngredient'],
	ReturnType<typeof IngredientFragment>
>
